var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: { model: _vm.queryParams, size: "small", inline: true },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称", prop: "roleName" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: "请输入角色名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "roleName", $$v)
                  },
                  expression: "queryParams.roleName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权限字符", prop: "roleKey" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: "请输入权限字符", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.roleKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "roleKey", $$v)
                  },
                  expression: "queryParams.roleKey",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("status.status"), prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: "角色状态", clearable: "" },
                  model: {
                    value: _vm.queryParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "status", $$v)
                    },
                    expression: "queryParams.status",
                  },
                },
                _vm._l(_vm.dict.type.sys_normal_disable, function (dict) {
                  return _c("el-option", {
                    key: dict.value,
                    attrs: { label: dict.label, value: dict.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("time.createTime") } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": _vm.$t("time.startDate"),
                  "end-placeholder": _vm.$t("time.endingDate"),
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v(_vm._s(_vm.$t("search")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v(_vm._s(_vm.$t("reset")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:role:add"],
                      expression: "['system:role:add']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(_vm._s(_vm.$t("add")))]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:role:edit"],
                      expression: "['system:role:edit']",
                    },
                  ],
                  attrs: {
                    type: "success",
                    plain: "",
                    icon: "iconfont icon-edit",
                    size: "mini",
                    disabled: _vm.single,
                  },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v(_vm._s(_vm.$t("update")))]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:role:remove"],
                      expression: "['system:role:remove']",
                    },
                  ],
                  attrs: {
                    type: "danger",
                    plain: "",
                    icon: "iconfont icon-delete",
                    size: "mini",
                    disabled: _vm.multiple,
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(_vm._s(_vm.$t("remove")))]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:role:export"],
                      expression: "['system:role:export']",
                    },
                  ],
                  attrs: {
                    type: "warning",
                    plain: "",
                    icon: "iconfont icon-daoru",
                    size: "mini",
                  },
                  on: { click: _vm.handleExport },
                },
                [_vm._v(_vm._s(_vm.$t("export")))]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.roleList },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "角色编号", prop: "roleId", width: "120" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "角色名称",
              prop: "roleName",
              "show-overflow-tooltip": true,
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "权限字符",
              prop: "roleKey",
              "show-overflow-tooltip": true,
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "显示顺序", prop: "roleSort", width: "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("status.status"),
              align: "center",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": "0", "inactive-value": "1" },
                      on: {
                        change: function ($event) {
                          return _vm.handleStatusChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("time.createTime"),
              align: "center",
              prop: "createTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operate"),
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.roleId !== 1
                      ? [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["system:role:edit"],
                                  expression: "['system:role:edit']",
                                },
                              ],
                              attrs: {
                                size: "mini",
                                type: "text",
                                icon: "iconfont icon-edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdate(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("update")))]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["system:role:remove"],
                                  expression: "['system:role:remove']",
                                },
                              ],
                              attrs: {
                                size: "mini",
                                type: "text",
                                icon: "iconfont icon-delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("remove")))]
                          ),
                          _c(
                            "el-dropdown",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["system:role:edit"],
                                  expression: "['system:role:edit']",
                                },
                              ],
                              attrs: { size: "mini" },
                              on: {
                                command: function (command) {
                                  return _vm.handleCommand(command, scope.row)
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-d-arrow-right",
                                  },
                                },
                                [_vm._v("更多")]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["system:role:edit"],
                                          expression: "['system:role:edit']",
                                        },
                                      ],
                                      attrs: {
                                        command: "handleDataScope",
                                        icon: "el-icon-circle-check",
                                      },
                                    },
                                    [_vm._v("数据权限")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["system:role:edit"],
                                          expression: "['system:role:edit']",
                                        },
                                      ],
                                      attrs: {
                                        command: "handleAuthUser",
                                        icon: "el-icon-user",
                                      },
                                    },
                                    [_vm._v("分配用户")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "roleName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", placeholder: "请输入角色名称" },
                    model: {
                      value: _vm.form.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleName", $$v)
                      },
                      expression: "form.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "roleKey" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                      _vm._v(" 权限字符 "),
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入权限字符" },
                    model: {
                      value: _vm.form.roleKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleKey", $$v)
                      },
                      expression: "form.roleKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色顺序", prop: "roleSort" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0, max: 9999 },
                    model: {
                      value: _vm.form.roleSort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleSort", $$v)
                      },
                      expression: "form.roleSort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("status.status") } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.dict.type.sys_normal_disable, function (dict) {
                      return _c(
                        "el-radio",
                        { key: dict.value, attrs: { label: dict.value } },
                        [_vm._v(_vm._s(dict.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单权限" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleCheckedTreeExpand($event, "menu")
                        },
                      },
                      model: {
                        value: _vm.menuExpand,
                        callback: function ($$v) {
                          _vm.menuExpand = $$v
                        },
                        expression: "menuExpand",
                      },
                    },
                    [_vm._v("展开/折叠")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleCheckedTreeNodeAll($event, "menu")
                        },
                      },
                      model: {
                        value: _vm.menuNodeAll,
                        callback: function ($$v) {
                          _vm.menuNodeAll = $$v
                        },
                        expression: "menuNodeAll",
                      },
                    },
                    [_vm._v("全选/全不选")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleCheckedTreeConnect($event, "menu")
                        },
                      },
                      model: {
                        value: _vm.form.menuCheckStrictly,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "menuCheckStrictly", $$v)
                        },
                        expression: "form.menuCheckStrictly",
                      },
                    },
                    [_vm._v("父子联动")]
                  ),
                  _c("el-tree", {
                    ref: "menu",
                    staticClass: "tree-border",
                    attrs: {
                      data: _vm.menuOptions,
                      "show-checkbox": "",
                      "node-key": "id",
                      "check-strictly": !_vm.form.menuCheckStrictly,
                      "empty-text": "加载中，请稍候",
                      props: _vm.defaultProps,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.openDataScope,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDataScope = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", disabled: true },
                    model: {
                      value: _vm.form.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleName", $$v)
                      },
                      expression: "form.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限字符" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", disabled: true },
                    model: {
                      value: _vm.form.roleKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleKey", $$v)
                      },
                      expression: "form.roleKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限范围" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.dataScopeSelectChange },
                      model: {
                        value: _vm.form.dataScope,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dataScope", $$v)
                        },
                        expression: "form.dataScope",
                      },
                    },
                    _vm._l(_vm.dataScopeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.dataScope == 2,
                      expression: "form.dataScope == 2",
                    },
                  ],
                  attrs: { label: "数据权限" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleCheckedTreeExpand($event, "dept")
                        },
                      },
                      model: {
                        value: _vm.deptExpand,
                        callback: function ($$v) {
                          _vm.deptExpand = $$v
                        },
                        expression: "deptExpand",
                      },
                    },
                    [_vm._v("展开/折叠")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleCheckedTreeNodeAll($event, "dept")
                        },
                      },
                      model: {
                        value: _vm.deptNodeAll,
                        callback: function ($$v) {
                          _vm.deptNodeAll = $$v
                        },
                        expression: "deptNodeAll",
                      },
                    },
                    [_vm._v("全选/全不选")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleCheckedTreeConnect($event, "dept")
                        },
                      },
                      model: {
                        value: _vm.form.deptCheckStrictly,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deptCheckStrictly", $$v)
                        },
                        expression: "form.deptCheckStrictly",
                      },
                    },
                    [_vm._v("父子联动")]
                  ),
                  _c("el-tree", {
                    ref: "dept",
                    staticClass: "tree-border",
                    attrs: {
                      data: _vm.deptOptions,
                      "show-checkbox": "",
                      "default-expand-all": "",
                      "node-key": "id",
                      "check-strictly": !_vm.form.deptCheckStrictly,
                      "empty-text": "加载中，请稍候",
                      props: _vm.defaultProps,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitDataScope },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
              _c("el-button", { on: { click: _vm.cancelDataScope } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }