<template>
  <div class="app-container">
    <!-- <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="" prop="label">
        <el-input
          v-model="queryParams.label"
          :placeholder="$t('label.hintLabel')"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="daterangeCreateTime"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('time.startDate')"
          :end-placeholder="$t('time.endingDate')"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{$t('search')}}</el-button>
        <el-tooltip class="item" effect="dark" :content="$t('refresh')" placement="top">
          <el-button size="mini" circle icon="el-icon-refresh" @click="getList" />
        </el-tooltip>
      </el-form-item>
    </el-form> -->

    <!-- <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:label:add']"
        >{{ $t('add') }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="iconfont icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:label:edit']"
        >{{ $t('update') }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="iconfont icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:label:remove']"
        >{{$t('remove')}}</el-button>
      </el-col>
    </el-row> -->

    <div class="flex-row-center f-between w-100 mb10">
      <div class="f-shrink">
        <el-button
          class="gradient-button"
          icon="el-icon-plus"
          @click="handleAdd"
        >{{ $t('add') }}</el-button>
        <el-button
          class="delete-button"
          icon="iconfont icon-delete"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:label:remove']"
        >{{$t('remove')}}</el-button>
      </div>
      <div class="flex-row-center">
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.label"
            :placeholder="$t('label.hintLabel')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <el-button
            size="mini"
            circle
            icon="el-icon-refresh"
            @click="getList"
          />
        </el-tooltip>
      </div>
    </div>

    <el-table
      header-row-class-name="custom_table_header"
      height="calc(100vh - 320px)"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      v-loading="loading"
      :data="labelList"
      @selection-change="handleSelectionChange"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <!-- <el-table-column label="ID" align="center" prop="id" v-if="false"/> -->
      <el-table-column
        :label="$t('label.label')"
        align="center"
        prop="label"
      />
      <el-table-column
        align="center"
        prop="createTime"
      >
        <template slot-scope="scope">
          <div v-show="scope.row.isHoverCell">
            <el-button
              size="mini"
              type="text"
              icon="iconfont icon-edit"
              @click="handleUpdate(scope.row)"
              v-hasPermi="['system:label:edit']"
            >{{ $t('update') }}</el-button>
            <el-button
              size="mini"
              type="text"
              icon="iconfont icon-delete"
              @click="handleDelete(scope.row)"
              v-hasPermi="['system:label:remove']"
            >{{$t('remove')}}</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('time.createTime')"
        align="center"
        prop="createTime"
      >
        <template slot-scope="scope">
          <span v-show="!scope.row.isHoverCell">{{ parseTime(scope.row.createTime, '{y}-{m}-{d}') }}</span>
          <!-- <div v-show="scope.row.isHoverCell">
            <el-button
              size="mini"
              type="text"
              icon="iconfont icon-edit"
              @click="handleUpdate(scope.row)"
              v-hasPermi="['system:label:edit']"
            >{{ $t('update') }}</el-button>
            <el-button
              size="mini"
              type="text"
              icon="iconfont icon-delete"
              @click="handleDelete(scope.row)"
              v-hasPermi="['system:label:remove']"
            >{{$t('remove')}}</el-button>
          </div> -->
        </template>
      </el-table-column>
      <!-- <el-table-column :label="$t('operate')" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="iconfont icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:label:edit']"
          >{{ $t('update') }}</el-button>
          <el-button
            size="mini"
            type="text"
            icon="iconfont icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:label:remove']"
          >{{$t('remove')}}</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改标签管理对话框 -->
    <el-dialog
      :title="title"
      class="publicDialog"
      :visible.sync="open"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
      append-to-body
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ title }}</div>
          <i
            class="el-icon-close"
            @click="cancel"
          ></i>
        </div>
      </div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="60px"
      >
        <el-form-item
          label="标签"
          prop="label"
        >
          <el-input
            v-model="form.label"
            maxlength="50"
            show-word-limit
            :placeholder="$t('tip.input')"
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancelBtn"
          @click="cancel"
        >取 消</el-button>
        <el-button
          class="submitBtn"
          :loading="buttonLoading"
          type="primary"
          @click="submitForm"
        >{{ $t('confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listLabel,
  getLabel,
  delLabel,
  addLabel,
  updateLabel,
} from "@/api/system/label";

export default {
  name: "Label",
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      labels: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 标签管理表格数据
      labelList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 时间范围
      daterangeCreateTime: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        label: undefined,
        createTime: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        label: [
          { required: true, message: "标签不能为空", trigger: "blur" },
          {
            max: 20,
            message: this.$t("label.formRulesLabelLength"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    cellMouseLeave(row, column, cell, event) {
      if (this.isViewVideo) return;
      row.isHoverCell = false;
    },
    cellMouseEnter(row, column, cell, event) {
      row.isHoverCell = true;
    },
    /** 查询标签管理列表 */
    getList() {
      this.loading = true;
      this.queryParams.params = {};
      if (null != this.daterangeCreateTime && "" != this.daterangeCreateTime) {
        this.queryParams.params["beginCreateTime"] =
          this.daterangeCreateTime[0];
        this.queryParams.params["endCreateTime"] = this.daterangeCreateTime[1];
      }
      listLabel(this.queryParams).then((response) => {
        const newList = response.rows.map((item) => {
          return { ...item, isHoverCell: false };
        });
        this.labelList = newList;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        label: undefined,
        merchantId: undefined,
        createBy: undefined,
        updateBy: undefined,
        createTime: undefined,
        updateTime: undefined,
        delFlag: undefined,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeCreateTime = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.labels = selection.map((item) => item.label);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加标签";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.loading = true;
      this.reset();
      const id = row.id || this.ids;
      getLabel(id).then((response) => {
        this.loading = false;
        this.form = response.data;
        this.open = true;
        this.title = "修改标签";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          if (this.form.id != null) {
            updateLabel(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.update"));
                this.open = false;
                this.getList();
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          } else {
            addLabel(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.add"));
                this.open = false;
                this.getList();
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      const labels = row.label || this.labels;
      this.$modal
        .confirm('是否确认删除"' + labels + '"的标签？')
        .then(() => {
          this.loading = true;
          return delLabel(ids);
        })
        .then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "system/label/export",
        {
          ...this.queryParams,
        },
        `label_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>
