var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "配置key", prop: "configKey" } },
            [
              _c("el-input", {
                attrs: { placeholder: "配置key", clearable: "", size: "small" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.configKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "configKey", $$v)
                  },
                  expression: "queryParams.configKey",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "桶名称", prop: "bucketName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入桶名称",
                  clearable: "",
                  size: "small",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.bucketName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "bucketName", $$v)
                  },
                  expression: "queryParams.bucketName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("status.status"), prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择状态",
                    clearable: "",
                    size: "small",
                  },
                  model: {
                    value: _vm.queryParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "status", $$v)
                    },
                    expression: "queryParams.status",
                  },
                },
                _vm._l(_vm.dict.type.sys_normal_disable, function (dict) {
                  return _c("el-option", {
                    key: dict.value,
                    attrs: { label: dict.label, value: dict.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v(_vm._s(_vm.$t("search")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v(_vm._s(_vm.$t("reset")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:oss:add"],
                      expression: "['system:oss:add']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(_vm._s(_vm.$t("add")))]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:oss:edit"],
                      expression: "['system:oss:edit']",
                    },
                  ],
                  attrs: {
                    type: "success",
                    plain: "",
                    icon: "iconfont icon-edit",
                    size: "mini",
                    disabled: _vm.single,
                  },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v(_vm._s(_vm.$t("update")))]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:oss:remove"],
                      expression: "['system:oss:remove']",
                    },
                  ],
                  attrs: {
                    type: "danger",
                    plain: "",
                    icon: "iconfont icon-delete",
                    size: "mini",
                    disabled: _vm.multiple,
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(_vm._s(_vm.$t("remove")))]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.ossConfigList },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          false
            ? _c("el-table-column", {
                attrs: { label: "主建", align: "center", prop: "ossConfigId" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "配置key", align: "center", prop: "configKey" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "访问站点",
              align: "center",
              prop: "endpoint",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "自定义域名",
              align: "center",
              prop: "domain",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "桶名称", align: "center", prop: "bucketName" },
          }),
          _c("el-table-column", {
            attrs: { label: "前缀", align: "center", prop: "prefix" },
          }),
          _c("el-table-column", {
            attrs: { label: "域", align: "center", prop: "region" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "桶权限类型",
              align: "center",
              prop: "accessPolicy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.accessPolicy === "0"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("private"),
                        ])
                      : _vm._e(),
                    scope.row.accessPolicy === "1"
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("public"),
                        ])
                      : _vm._e(),
                    scope.row.accessPolicy === "2"
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("custom"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("status.status"),
              align: "center",
              prop: "status",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": "0", "inactive-value": "1" },
                      on: {
                        change: function ($event) {
                          return _vm.handleStatusChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operate"),
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["system:oss:edit"],
                            expression: "['system:oss:edit']",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "iconfont icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("update")))]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["system:oss:remove"],
                            expression: "['system:oss:remove']",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "iconfont icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("remove")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "配置key", prop: "configKey" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入配置key" },
                    model: {
                      value: _vm.form.configKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "configKey", $$v)
                      },
                      expression: "form.configKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "访问站点", prop: "endpoint" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入访问站点" },
                    model: {
                      value: _vm.form.endpoint,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endpoint", $$v)
                      },
                      expression: "form.endpoint",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自定义域名", prop: "domain" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "200",
                      placeholder: "请输入自定义域名",
                    },
                    model: {
                      value: _vm.form.domain,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "domain", $$v)
                      },
                      expression: "form.domain",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "accessKey", prop: "accessKey" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入accessKey" },
                    model: {
                      value: _vm.form.accessKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "accessKey", $$v)
                      },
                      expression: "form.accessKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "secretKey", prop: "secretKey" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "200",
                      placeholder: "请输入秘钥",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.form.secretKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "secretKey", $$v)
                      },
                      expression: "form.secretKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "桶名称", prop: "bucketName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入桶名称" },
                    model: {
                      value: _vm.form.bucketName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bucketName", $$v)
                      },
                      expression: "form.bucketName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "前缀", prop: "prefix" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入前缀" },
                    model: {
                      value: _vm.form.prefix,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prefix", $$v)
                      },
                      expression: "form.prefix",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否HTTPS" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isHttps,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isHttps", $$v)
                        },
                        expression: "form.isHttps",
                      },
                    },
                    _vm._l(_vm.dict.type.sys_yes_no, function (dict) {
                      return _c(
                        "el-radio",
                        { key: dict.value, attrs: { label: dict.value } },
                        [_vm._v(_vm._s(dict.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "桶权限类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.accessPolicy,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "accessPolicy", $$v)
                        },
                        expression: "form.accessPolicy",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("private"),
                      ]),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("public"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("custom"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "域", prop: "region" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入域" },
                    model: {
                      value: _vm.form.region,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "region", $$v)
                      },
                      expression: "form.region",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading, type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }